




























































import { defineComponent, ref } from '@vue/composition-api'

const ConfirmZipOrPdfDialog = defineComponent({
  props: {
    value: {
      type: Boolean,
      required: true
    },
    isSingle: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup() {
    const check = ref('pdf')
    const invoiceType = ref('d1')

    return {
      check,
      invoiceType
    }
  }
})

export default ConfirmZipOrPdfDialog
