






































import { defineComponent, ref, watch } from '@vue/composition-api'
import { api } from 'plugins'
import { InvoiceEmailDestination } from 'typings'
import { endpoints, toCamelCase, showError } from 'utils'
import { SettingsInvoiceMethodPanel } from 'components'

interface Member {
  id: number
  name: string
  email: string
  customerId: number
}
interface CustomerInfo {
  name: string
  yomi: string
  shortName: string
  tel: string
  fax: string
  email: string
  invoiceEmail: string
  code: string
  zipCode: string
  address1: string
  address2: string
  invoiceMethod: Array<string> | null
  invoiceEmailDestination: InvoiceEmailDestination | null
  allowPreSale: boolean
  customerGroupId: number | null
}

const EditSendTos = defineComponent({
  components: {
    SettingsInvoiceMethodPanel
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    customerId: {
      type: Number,
      required: false,
      default: 0
    }
  },
  setup(props, { root, emit }) {
    const DEFAULT_INVOICE_EMAIL = 0
    const { $toast } = root
    const customerInfoDefault: CustomerInfo = {
      name: '',
      yomi: '',
      shortName: '',
      tel: '',
      fax: '',
      email: '',
      invoiceEmail: '',
      code: '',
      zipCode: '',
      address1: '',
      address2: '',
      invoiceMethod: null,
      invoiceEmailDestination: null,
      allowPreSale: true,
      customerGroupId: null
    }
    const customerInfo = ref<CustomerInfo>(customerInfoDefault)
    const listMember = ref<Array<Member>>([])
    const getSendTos = (method: string) => {
      const emailDestination = customerInfo.value.invoiceEmailDestination
      let sendTos = ''
      if (method === 'fax') {
        sendTos = customerInfo.value.fax
      } else if (method === 'mail' || method === 'data') {
        let memberCustomerIds = [] as string[]
        if (method === 'mail') {
          memberCustomerIds = emailDestination?.mail.split(',') || []
        } else {
          memberCustomerIds = emailDestination?.data.split(',') || []
        }
        sendTos = memberCustomerIds
          ?.map((e: string) => {
            let email = null
            if (Number(e) === DEFAULT_INVOICE_EMAIL) {
              email = customerInfo.value.invoiceEmail
            } else {
              email = listMember.value.find((member: Member) => member.id === Number(e))?.email
            }
            return email
          })
          .filter((e: any) => {
            return e != null && !!e.length
          })
          .join(', ')
      }
      return sendTos
    }
    const updateSendTos = () => {
      const deliveries = customerInfo.value.invoiceMethod?.map((method: string) => ({
        send_type: method,
        send_tos: getSendTos(method)
      }))
      emit('on-ok', deliveries)
    }
    watch(
      () => props.value,
      async (value) => {
        if (!value) {
          return
        }
        if (props.customerId === 0) {
          customerInfo.value = customerInfoDefault
          listMember.value = []
        } else {
          try {
            const [{ data: dataMemberList }, { data: dataCustomerInfo }] = await Promise.all([
              api.get(`${endpoints.CUSTOMER_MEMBER}?customer_id=${props.customerId}`),
              api.get(`${endpoints.CUSTOMERS}${props.customerId}`)
            ])
            listMember.value = dataMemberList?.map((member: any) => {
              return {
                id: member.id,
                name: member.name,
                email: member.email,
                customer_id: member.customer_id
              }
            })
            customerInfo.value = toCamelCase(dataCustomerInfo)
          } catch (e) {
            showError(e, $toast, root.$t('master.msg.get_data_failed') as string)
          }
        }
      }
    )

    return {
      customerInfo,
      listMember,
      updateSendTos
    }
  }
})

export default EditSendTos
