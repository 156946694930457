

































import { defineComponent } from '@vue/composition-api'

const ConfirmSendInvoice = defineComponent({
  props: {
    value: {
      type: Boolean,
      required: true
    },
    type: {
      // Type: "d1" or "d2"
      type: String,
      required: true
    }
  }
})

export default ConfirmSendInvoice
